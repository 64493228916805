import { AuthProvider } from 'react-admin';
import decodeJwt from 'jwt-decode';
import { apiUrl } from "./config";


export const authProvider: AuthProvider = {
  login: ({ username, password }: any) => {
    const request = new Request(`${apiUrl}/login`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password: password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem('token', data.token);
      });
  },
  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return Promise.reject();
    }
    const decodedToken:any = decodeJwt(token);
    const { exp } = decodedToken;
    if (Date.now() >= exp * 1000) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    const role = localStorage.getItem('role');
    return role ? Promise.resolve(role) : Promise.reject();
  },
};