import {
  useRecordContext,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  required
} from "react-admin";

const OptionTitle = () => {
  const record = useRecordContext();
  return <span>Option {record ? `"${record.title}"` : ""}</span>;
};

export const OptionEdit = () => (
  <Edit title={<OptionTitle />}>
    <SimpleForm>
      <TextInput fullWidth source="title" validate={required()} />
      <TextInput fullWidth multiline rows={5} source="description" />
      <TextInput fullWidth source="mews_id" validate={required()} />
      <TextInput fullWidth source="type" />
      <NumberInput source="price" validate={required()} />
      <BooleanInput fullWidth source="is_active" />
    </SimpleForm>
  </Edit>
);
