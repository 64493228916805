import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  NumberField,
  EditButton,
} from "react-admin";

export const PriceList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="room_id" reference="room_category" />
      <DateField source="start_date" />
      <DateField source="end_date" />
      <NumberField className="text-center" source="price_curist_companion" label="1 curiste + 1 accompagagnant" />
      <NumberField className="text-center" source="price_single" label="1 curiste"/>
      <NumberField className="text-center" source="price_two_curists" label="2 curistes"/>
      <ReferenceField source="stay_id" reference="stay" />
      <EditButton />
    </Datagrid>
  </List>
);
