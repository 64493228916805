import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dataProvider } from "../dataprovider/dataProvider";
import "../styles.css";
import { useLocation } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import dayjs from "dayjs";

export const CalendrierPrix = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState<
    { id: number; name: string; prices_single: number[]; price_two_curists: number[]; price_curist_companion: number[] }[]
  >([]);
  const [bedroom, setBedroom] = useState("");
  const [showPanel, setShowPanel] = useState(false);
  const [roomId, setRoomId] = useState("");
  const [dateStart, setDateStart] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(new Date());

  //fonction lors du clique sur une case du tableau
  const handlePanelClick = (room_id: number, mydate: string) => {
    setRoomId(room_id.toString());
    const parts = mydate.split("/");
    const dateObject = new Date(
      parseInt(parts[2]),
      parseInt(parts[1]) - 1,
      parseInt(parts[0])
    );
    setDateStart(dateObject);
    setDateEnd(dateObject);
    setShowPanel(true);
  };

  // Fonction appeler lors du clique sur le bouton envoyer
  const BoutonSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const priceSingle = formData.get("price_single");
    const price_two_curists = formData.get("price_two_curists");
    const price_curist_companion = formData.get("price_curist_companion");
    const data = {
      start_date: dateStart,
      end_date: dateEnd,
      price_single: priceSingle !== '' ? priceSingle : null,
      price_two_curists: price_two_curists !== '' ? price_two_curists : null,
      price_curist_companion: price_curist_companion !== '' ? price_curist_companion : null,
      is_blocked: false,
      room_id: parseInt(roomId),
    };


    await dataProvider.create("price", { data: data });

    handleDateChange(selectedDate);

    setShowPanel(false);
  };

  // recupere l'id donné en paramêtre
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  useEffect(() => {
    fetchData();
    fetchBedroom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  // transforme la date sans heure min et sec
  function getDate(dayOffset: number, date: Date) {
    const dateObj = new Date(date.getTime());
    dateObj.setDate(dateObj.getDate() + dayOffset);
    return dateObj.toLocaleDateString();
  }

  // Renvoie le titre des colonnes avec les dates
  const columns = [];
  for (let i = 0; i < 14; i++) {
    columns.push(
      <th style={{ paddingLeft: "10px", paddingRight: "10px" }} key={i}>
        {getDate(i, selectedDate)}
      </th>
    );
  }

  // Quand la date du datepicker change
  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    fetchData();
    fetchBedroom();
  };

  // Recupere le nom du sejour
  const fetchBedroom = async () => {
    const { data } = await dataProvider.getOne("room_category", { id });
    setBedroom(data.title);
    setRoomId(data.id);
  };

  // changement de la valeur du formulaire de date start
  const changeValueStart = (event: any) => {
    setDateStart(event.target.value);
  };

  // changement de la valeur du formulaire de date end
  const changeValueEnd = (event: any) => {
    setDateEnd(event.target.value);
  };

  // recupere le prix des categories de chambres
  const fetchData = async () => {
    const startDate = new Date(selectedDate);
    const endDate = new Date(selectedDate);
    endDate.setDate(selectedDate.getDate() + 13);

    const { data } = await dataProvider.getOne("bedroom_price", {
      id: `${id}/${startDate.toISOString().slice(0, 10)}/${endDate
        .toISOString()
        .slice(0, 10)}`,
    });

    setData(data);
  };

  return (
    <div className="my-list">
      <h1 className="text-center">{bedroom}</h1>
      <div className="my-datepicker">
        <h4 style={{ marginBottom: "0px" }}>Date de début :</h4>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => handleDateChange(date as Date)}
          dateFormat="dd/MM/yyyy"
          className="my-custom-datepicker"
        />
      </div>
      <table className="my-table">
        <thead>
          <tr>
            <th></th>
            {columns}
          </tr>
        </thead>
        <tbody>
          {data.map((chambre, index) => (
            <>
            <tr key={chambre.id} className={index % 2 === 0 ? "even" : ""}>
              <td>1 Curiste</td>
              {chambre.prices_single.map((prix, index) => (
                <td
                  className="pointer"
                  key={index}
                  onClick={() =>
                    handlePanelClick(chambre.id, getDate(index, selectedDate))
                  }
                >
                  {prix} €
                </td>
              ))}
            </tr>
            <tr key={chambre.id} className={index % 2 === 0 ? "even" : ""}>
              <td>2 Curistes</td>
              {chambre.price_two_curists.map((prix, index) => (
                <td
                  className="pointer"
                  key={index}
                  onClick={() =>
                    handlePanelClick(chambre.id, getDate(index, selectedDate))
                  }
                >
                  {prix} €
                </td>
              ))}
            </tr>
            <tr key={chambre.id} className={index % 2 === 0 ? "even" : ""}>
              <td>1 Curiste + 1 Accompagnant</td>
              {chambre.price_curist_companion.map((prix, index) => (
                <td
                  className="pointer"
                  key={index}
                  onClick={() =>
                    handlePanelClick(chambre.id, getDate(index, selectedDate))
                  }
                >
                  {prix} €
                </td>
              ))}
            </tr>
            
            </>
            
          ))}
        </tbody>
      </table>
      {showPanel && (
        <div className="panel">
          <p className="cross pointer" onClick={() => setShowPanel(false)}>
            <ClearIcon />
          </p>

          <form
            className="createPrice"
            onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
              BoutonSubmit(event)
            }
          >
            <div>
              <label htmlFor="start_date">
                Date de début :
              </label>
              <input
                className="inputform"
                required
                type="datetime-local"
                id="start_date"
                name="start_date"
                value={
                  dateStart ? dayjs(dateStart).format("YYYY-MM-DDTHH:mm") : ""
                }
                onChange={changeValueStart}
              />
            </div>
            <div>
              <label htmlFor="end_date">Date de fin :</label>
              <input
                className="inputform"
                required
                type="datetime-local"
                id="end_date"
                name="end_date"
                value={
                  dateStart ? dayjs(dateEnd).format("YYYY-MM-DDTHH:mm") : ""
                }
                onChange={changeValueEnd}
              />
            </div>
            <div>
              <label htmlFor="price_single">Prix 1 curiste :</label>
              <input
                className="inputform"
                type="number"
                id="price_single"
                name="price_single"
              />
            </div>
            <div>
              <label htmlFor="price_two_curists">Prix 2 curistes :</label>
              <input
                className="inputform"
                type="number"
                id="price_two_curists"
                name="price_two_curists"
              />
            </div>
            <div>
              <label htmlFor="price_curist_companion">Prix 1 curiste + 1 accompagnant:</label>
              <input
                className="inputform"
                type="number"
                id="price_curist_companion"
                name="price_curist_companion"
              />
            </div>

            <button className="submitform" type="submit">
              Envoyer
            </button>
          </form>
        </div>
      )}
    </div>
  );
};
