import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import logo from './logo_btr.png';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginLeft: -10
    },
    spacer: {
        flex: 1,
    },
    logo: {
        marginLeft: -10,
        marginRight: 10
    },
});
  
const MyAppBar = props => {
const classes = useStyles();
    return (
        <AppBar {...props} color='secondary' >
        <Toolbar>
            <img src={logo} alt="logo" className={classes.logo} />
        </Toolbar>
        <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
        ></Typography>
        <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        />
        </AppBar >
    );
};

export default MyAppBar;
