import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { apiUrl } from "../config";

const httpClient = fetchUtils.fetchJson;

function token() {
  const token = localStorage.getItem("token");
  const headers = new Headers();
  headers.set("Authorization", `Bearer ${token}`);
  return headers;
}

// TypeScript users must reference the type `DataProvider`
export const dataProvider = {
  // Recupere une liste
  getList: (
    resource: any,
    params: {
      pagination: { page: any; perPage: any };
      sort: { field: any; order: any };
      filter: any;
    }
  ) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url, { headers: token() }).then(({ headers, json }) => ({
      data: json.resultat,
      total: json.total,
    }));
  },

  // Recupere un seul element
  getOne: (resource: any, params: { id: any }) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, { headers: token() }).then(
      ({ json }) => ({
        data: json,
      })
    ),

  // Recupere plusieurs elements
  getMany: (resource: any, params: { ids: any }) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url, { headers: token() }).then(({ json }) => ({
      data: json.resultat,
    }));
  },

  // Recupere plusieurs references
  getManyReference: (
    resource: any,
    params: {
      pagination: { page: any; perPage: any };
      sort: { field: any; order: any };
      filter: any;
      target: any;
      id: any;
    }
  ) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url, { headers: token() }).then(({ headers, json }) => ({
      data: json,
      total: 10,
    }));
  },

  // Mets a jour un element
  update: async (resource: any, params: {  id: any; data: any }) => {
    let mydata;
    if (resource === "stay") {
      mydata = await convertDataToBase64(params);
    } else {
      mydata = params.data;
    }

    return  httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      headers: token(),
      body: JSON.stringify(mydata),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },



  // Met a jour plusieurs elements
  updateMany: (resource: any, params: { ids: any; data: any }) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      headers: token(),
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  // Creer un element
  create: async (resource: any, params: { data: any }) => {
    let mydata;
    if (resource === "stay") {
      mydata = await convertDataToBase64(params);
    } else {
      mydata = params.data;
    }

    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      headers: token(),
      body: JSON.stringify(mydata),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  // Supprime un element
  delete: (resource: any, params: { id: any }) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
      headers: token(),
    }).then(({ json }) => ({ data: json })),

  // Supprime plusieurs elements
  deleteMany: (resource: any, params: { ids: any }) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: "DELETE",
      headers: token(),
      body: JSON.stringify({ ids: params.ids }),
    }).then(({ json }) => ({ data: json }));
  },
};

/*
const convertDataToBlob = async (params: { data: any; id?: any }) => {
  let mydata = params.data;

  const convertFileToBlob = (file: Blob) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsArrayBuffer(file);
    });
  };

  let newPictures: { blob: any }[] | { blob: Uint8Array; title: any }[] = [];

  if (mydata.image.rawFile instanceof File) {
    const blobData = (await convertFileToBlob(
      mydata.image.rawFile
    )) as ArrayBuffer;
    const uint8Array = new Uint8Array(blobData);
    newPictures = [
      {
        blob: uint8Array,
        title: mydata.image.title,
      },
    ];
  }

  mydata.image = newPictures[0].blob;

  return mydata;
};
*/

const convertDataToBase64 = async (params: { data: any }) => {
  let mydata = params.data;

  const resizeImage = (file: File, maxWidth: number, maxHeight: number) => {
    return new Promise<File>((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        let width = img.width;
        let height = img.height;
  
        if (width > maxWidth || height > maxHeight) {
          const aspectRatio = width / height;
          if (width > maxWidth) {
            width = maxWidth;
            height = width / aspectRatio;
          }
          if (height > maxHeight) {
            height = maxHeight;
            width = height * aspectRatio;
          }
        }
  
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
  
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0, width, height);
  
          canvas.toBlob((blob) => {
            if (blob) {
              resolve(new File([blob], file.name, { type: file.type }));
            } else {
              reject(new Error('Failed to convert canvas to blob.'));
            }
          }, file.type, 1);
        } else {
          reject(new Error('Unable to get canvas context.'));
        }
      };
      img.onerror = (error) => {
        reject(error);
      };
    });
  };
  
  

  // Function to convert a File to Base64 string
  const convertFileToBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  // Freshly dropped picture is a File object and must be converted to a base64 string
  let newPictures: { src: string; title: any }[] = [];

  if (mydata.image.rawFile instanceof File) {
    const resizedImage = await resizeImage(mydata.image.rawFile, 300, 300);
    const base64Data = await convertFileToBase64(resizedImage);
    newPictures = [
      {
        src: base64Data,
        title: mydata.image.title,
      },
    ];
  }

  // Update the data with transformed pictures
  if (newPictures[0]){
    mydata.image = newPictures[0].src.toString();
  }
 

  return mydata;
};


