import { Create, ReferenceInput, SimpleForm, TextInput , required} from "react-admin";

export const HotelCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" fullWidth validate={required()} />
      <TextInput source="description" fullWidth multiline rows={5} />
      <ReferenceInput source="city_id" reference="city" label="Ville" fullWidth validate={required()} />
    </SimpleForm>
  </Create>
);
