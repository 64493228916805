import {
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  useRecordContext,
  required
} from "react-admin";
import { Grid } from '@mui/material';

const BedroomTitle = () => {
  const record = useRecordContext();
  return <span>Chambre {record ? `"${record.title}"` : ""}</span>;
};

export const BedroomEdit = () => (
  <Edit title={<BedroomTitle />}>
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReferenceInput source="hotel_id" reference="hotel" validate={required()}/>
        </Grid>
        <Grid item xs={12}>
          <TextInput source="title" fullWidth label="Nom" validate={required()}/>
        </Grid>
        <Grid item xs={12}>
          <NumberInput source="capacity" fullWidth label="Capacité" validate={required()}/>
        </Grid>
        <Grid item xs={12}>
          <NumberInput source="base_rate" fullWidth validate={required()}/>
        </Grid>
        <Grid item xs={12}>
          <TextInput source="mews_id" fullWidth validate={required()}/>
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
);
