import {
  List,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  EditButton,
} from "react-admin";

export const OptionList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" label="Titre"/>
      <TextField source="description" />
      <TextField source="type" />
      <NumberField source="price" label="Prix €"/>
      <TextField source="mews_id" />
      <EditButton />
    </Datagrid>
  </List>
);
