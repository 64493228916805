import { Admin, CustomRoutes, Resource } from "react-admin";

import CategoryIcon from "@mui/icons-material/Category";
import SettingsIcon from "@mui/icons-material/Settings";
import EuroIcon from "@mui/icons-material/Euro";
import HotelIcon from "@mui/icons-material/Hotel";
import SingleBedIcon from "@mui/icons-material/SingleBed";
import LuggageIcon from "@mui/icons-material/Luggage";
import City from '@mui/icons-material/LocationCity';
import { Route } from "react-router-dom";
import { Dashboard } from "./Dashboard";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { dataProvider } from "./dataprovider/dataProvider";
import { authProvider } from "./authProvider";
import { CalendrierPrix } from "./bedroom/tarifs";
// Import Bedroom
import { BedroomList } from "./bedroom/bedroomList";
import { BedroomCreate } from "./bedroom/bedroomCreate";
import { BedroomEdit } from "./bedroom/bedroomEdit";
// Import Category
import { CategoryList } from "./category/categoryList";
import { CategoryEdit } from "./category/categoryEdit";
import { CategoryCreate } from "./category/categoryCreate";
// Import Hotel
import { HotelCreate } from "./hotel/hotelCreate";
import { HotelEdit } from "./hotel/hotelEdit";
import { HotelList } from "./hotel/hotelList";
// Import Option
import { OptionCreate } from "./option/optionCreate";
import { OptionEdit } from "./option/optionEdit";
import { OptionList } from "./option/optionList";
// Import Price
import { PriceCreate } from "./price/priceCreate";
import { PriceEdit } from "./price/priceEdit";
import { PriceList } from "./price/priceList";
// Import Stay
import { StayCreate } from "./stay/stayCreate";
import { StayEdit } from "./stay/stayEdit";
import { StayList } from "./stay/stayList";

import { MyLayout } from './MyLayout';
import { cityCreate } from "./city/cityCreate";
import { cityEdit } from "./city/cityEdit";
import { cityList } from "./city/cityList";

const App = () => {
  return (
    <Admin
      layout={MyLayout}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      <Resource
        name="category"
        options={{ label: "Catégories" }}
        list={CategoryList}
        edit={CategoryEdit}
        create={CategoryCreate}
        icon={CategoryIcon}
        recordRepresentation="title"
      ></Resource>

      <Resource
        name="stay_option"
        options={{ label: "Options" }}
        list={OptionList}
        edit={OptionEdit}
        create={OptionCreate}
        icon={SettingsIcon}
      ></Resource>

      <Resource
        name="stay"
        options={{ label: "Séjours" }}
        list={StayList}
        create={StayCreate}
        edit={StayEdit}
        recordRepresentation="title"
        icon={HotelIcon}
      ></Resource>

      <Resource
        name="price"
        options={{ label: "Prix" }}
        list={PriceList}
        create={PriceCreate}
        edit={PriceEdit}
        icon={EuroIcon}
      ></Resource>

      <Resource
        name="room_category"
        options={{ label: "Catégories chambres" }}
        list={BedroomList}
        create={BedroomCreate}
        edit={BedroomEdit}
        recordRepresentation="title"
        icon={SingleBedIcon}
      ></Resource>

<Resource
        name="city"
        options={{ label: "Villes" }}
        list={cityList}
        create={cityCreate}
        edit={cityEdit}
        recordRepresentation="name"
        icon={City}
      ></Resource>

      <Resource
        name="hotel"
        options={{ label: "Hôtels" }}
        list={HotelList}
        create={HotelCreate}
        edit={HotelEdit}
        recordRepresentation="name"
        icon={LuggageIcon}
      ></Resource>

      <CustomRoutes>
        <Route path="/tarifs" element={<CalendrierPrix />} />
      </CustomRoutes>
    </Admin>
  );
};

export default App;
