import {
  Edit,
  SimpleForm,
  ReferenceInput,
  DateTimeInput,
  NumberInput,
  BooleanInput,
  required
} from "react-admin";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { Grid } from '@mui/material';

export const PriceEdit = () => (
  <Edit>
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReferenceInput source="room_id" reference="room_category" label="Catégorie de chambre" fullWidth validate={required()} />
        </Grid>
        <Grid item xs={12}>
        <div className="datetime-input-container">
        <DateTimeInput className="dateInput" source="start_date" label="Date de début" validate={required()} />
        <span className="arrow-icon-container">
          <ArrowForwardIcon className="arrow-icon" />
        </span>
        <DateTimeInput className="dateInput" source="end_date" label="Date de fin" validate={required()} />
      </div>
        </Grid>

        <Grid item xs={12}>
          <NumberInput source="price_curist_companion" label="Prix 1 curiste et 1 accompagnant" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <NumberInput source="price_single" label="Prix 1 curiste" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <NumberInput source="price_two_curists" label="Prix 2 curistes" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <BooleanInput source="is_blocked" label="Bloquer" validate={required()}/>
        </Grid>
        <Grid item xs={12}>
          <ReferenceInput source="stay_id" reference="stay" label="Cure" fullWidth validate={required()} />
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
);
