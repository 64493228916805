import { Card, CardHeader, Link } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

export const Dashboard = () => (
  <Card style={{ padding: '16px' }}>
    <CardHeader title="Bienvenue dans l'administration du moteur de réservation Biarritz Thalasso Resort." />
    <Link href="https://booking.biarritz-thalasso.com/" target="_blank" style={{ padding: '16px' }}>Voir le site <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: '5px' }} /></Link>
    <span></span>
  </Card>
);