import { Datagrid, EditButton, List, TextField } from "react-admin";

export const cityList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" label="Nom"/>
      <TextField source="code_postal" label="Code Postal"/>
      <EditButton />
    </Datagrid>
  </List>
);
