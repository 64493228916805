import { Create, SimpleForm, TextInput, required } from "react-admin";

export const CategoryCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="title" validate={required()} />
      <TextInput source="description" multiline rows={5} />
    </SimpleForm>
  </Create>
);
