import {
  useRecordContext,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  EditButton,
  ImageField,
} from "react-admin";

const DescriptionField = () => {
  const record = useRecordContext();
  return <span>{record.description.substring(0, 50)}...</span>;
};

export const StayList = () => (
  <List>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField source="category_id" reference="category" label="Catégorie"/>
      <ReferenceField source="hotel_id" reference="hotel" />
      <TextField source="mews_id" />
      <TextField source="title" label="Titre"/>
      <DescriptionField/>
      <NumberField source="nights" label="Nuits"/>
      <ImageField source="image" />
      <EditButton />
    </Datagrid>
  </List>
);
