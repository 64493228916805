import {
  useRecordContext,
  Edit,
  SimpleForm,
  ReferenceInput,
  TextInput,
  NumberInput,
  BooleanInput,
  ImageField,
  ImageInput,
  required,
} from "react-admin";

import { Grid } from "@mui/material";

const StayTitle = () => {
  const record = useRecordContext();
  return <span>Package {record ? `"${record.title}"` : ""}</span>;
};

export const StayEdit = () => (
  <Edit title={<StayTitle />}>
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReferenceInput
            source="category_id"
            reference="category"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12}>
          <ReferenceInput
            source="hotel_id"
            reference="hotel"
            validate={required()}
          />
        </Grid>
      </Grid>
      <TextInput fullWidth source="title" validate={required()} />
      <TextInput fullWidth source="mews_id" validate={required()} />
      <TextInput fullWidth multiline rows={5} source="description" />
      <NumberInput source="nights" validate={required()} />
      <BooleanInput source="is_active" validate={required()} />
      <ImageInput
        source="image"
        label="Image"
        accept="image/*"
        multiple={false}
        placeholder={<p>Sélectionnez votre image</p>}
        validate={required()}
      >
        <ImageField source="src" title="Image" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);
