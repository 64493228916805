import { useRecordContext, Edit, SimpleForm, TextInput, required, ReferenceInput } from "react-admin";

const HotelTitle = () => {
  const record = useRecordContext();
  return <span>Hotel {record ? `"${record.title}"` : ""}</span>;
};

export const HotelEdit = () => (
  <Edit title={<HotelTitle />}>
    <SimpleForm>
      <TextInput source="name" fullWidth validate={required()} />
      <TextInput source="description" fullWidth multiline rows={5} />
      <ReferenceInput source="city_id" reference="city" label="Ville" fullWidth validate={required()} />
    </SimpleForm>
  </Edit>
);
