import { Datagrid, EditButton, List, ReferenceField, TextField } from "react-admin";

export const HotelList = () => (
    <List>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" label="Nom"/>
            <TextField source="description" />
            <ReferenceField source="city_id" reference="city" label="Ville"/>
            <EditButton/>
        </Datagrid>
    </List>
);