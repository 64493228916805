import { Edit, SimpleForm, TextInput, useRecordContext, required } from "react-admin";


const CategoryTitle = () => {
  const record = useRecordContext();
  return <span>Category {record ? `"${record.title}"` : ""}</span>;
};

export const cityEdit = () => (
  <Edit title={<CategoryTitle />}>
    <SimpleForm>
      <TextInput source="name" fullWidth validate={required()}/>
      <TextInput source="code_postal" fullWidth  validate={required()}/>
    </SimpleForm>
  </Edit>
);
