import { Datagrid, EditButton, List, TextField } from "react-admin";

export const CategoryList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" label="Titre"/>
      <TextField source="description"/>
      <EditButton />
    </Datagrid>
  </List>
);
