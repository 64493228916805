import { Edit, SimpleForm, TextInput, useRecordContext, required } from "react-admin";


const CategoryTitle = () => {
  const record = useRecordContext();
  return <span>Category {record ? `"${record.title}"` : ""}</span>;
};

export const CategoryEdit = () => (
  <Edit title={<CategoryTitle />}>
    <SimpleForm>
      <TextInput source="title" fullWidth validate={required()}/>
      <TextInput source="description" fullWidth multiline rows={5} />
    </SimpleForm>
  </Edit>
);
