import {
  Button,
  Datagrid,
  EditButton,
  Link,
  List,
  NumberField,
  ReferenceField,
  TextField,
  useRecordContext,
} from "react-admin";
import EuroIcon from "@mui/icons-material/Euro";

const TarifsLinkField = () => {
  const record = useRecordContext();

  return (
    <Button component={Link} to={`/tarifs?id=${record?.id}`}>
      <div className="bouton-tarif">
        <span>
          <EuroIcon />
        </span>
        <span className="label">Tarifs</span>
      </div>
    </Button>
  );
};

export const BedroomList = () => (
  <List>
    <Datagrid >
      <TextField source="id" />
      <ReferenceField source="hotel_id" reference="hotel" />
      <TextField source="title" label="Titre"/>
      <NumberField source="capacity" label="Capacité"/>
      <NumberField source="base_rate" />
      <TextField source="mews_id" label="Mews"/>
      <TarifsLinkField />
      <EditButton />  
    </Datagrid>
  </List>
);
